import request from '@/utils/request'
import { AxiosPromise } from 'axios'
import { LoginResult } from '@/api/types'

/**
 * 获取会议室详情
 * @param data {LoginData}
 * @returns
 */
export function getMeetingDetail(params: any) {
  return request({
    url: `/meet/Meeting/getMeetingDetail?meeting_id=${params.meeting_id}`,
    method: 'get'
  })
}

/**
 * 创建会议室预约
 * @param data {LoginData}
 * @returns
 */
export function createOrder(data: any) {
  return request({
    url: `/meet/Index/createMeetOrder`,
    method: 'post',
    data
  })
}

/**
 * 参会人员列表
 * @param data {LoginData}
 * @returns
 */
export function participation(data: any) {
  return request({
    url: `/meet/user/participation`,
    method: 'post',
    data
  })
}

/**
 * 更新会议室预约
 * @param data {LoginData}
 * @returns
 */
export function updateMeetOrder(data: any) {
  return request({
    url: `/meet/Index/updateMeetOrder`,
    method: 'post',
    data
  })
}

